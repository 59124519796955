import React, { useEffect } from "react";
import "./Ambassadors.css";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import LandingHeroImage from "../../components/ambassadors/LandingHeroImage/LandingHeroImage";
import Support from "../../components/ambassadors/Support/Support";
import EventCards from "../../components/ambassadors/EventCards/EventCards";
import TaaraAmbassador from "../../components/ambassadors/TaaraAmbassador/TaaraAmbassador";
import PromiseYouEverything from "../../components/ambassadors/PromiseYouEverything/PromiseYouEverything";
import HowToJoin from "../../components/ambassadors/HowToJoin/HowToJoin";
import OurAmbassadors from "../../components/ambassadors/OurAmbassadors/OurAmbassadors";
import Doing from "../../components/ambassadors/Doing/Doing";
import Header from "../../components/Header/Header";
// import LandingHeroVideo from '../../components/ambassadors/LandingHeroVideo/LandingHeroVideo'

const Ambassadors = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const handleCTAClick = () =>
    window.open(
      "https://airtable.com/appXDqkHbjSrk9c7g/pagLxU9pUGpo7cuO7/form",
      "_blank"
    );
  return (
    <NonProtectedLayout>
      <Header
        title="Become a Taara Quest Ambassador: Empower Women, Shape Tech"
        description="Make a Positive Impact with Like-Minded Women | Taara Quest"
        keywords="Ambassador program,Women in leadership,Tech for social change, Empowerment in tech
, AI-based career coaching
, Breaking gender norms in leadership
, Roleplay learning tools
, Building networks in tech"
      />

      {/* <LandingHeroVideo src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Hero_Header-Image.webp" onClick={() => null}  /> */}
      <div className="AmbassadorsContainer">
        <LandingHeroImage
          alt="Ambassador Hero Image"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Hero_Header-Image.webp"
          heading="JOIN THE TAARA QUEST MOVEMENT"
          ctaText="Apply Now"
          onClick={handleCTAClick}
        />

        <div className="SomethingBigger">
          <h3 className="heading">Become part of something bigger.</h3>
          <p className="description">
            As a Taara Ambassador, you are not just spreading the word; you're
            shaping the future. Help us develop and test the products women
            truly need.
          </p>
          <div className="WaitListButton" onClick={() => {}}>
            Join the Waitlist
          </div>
        </div>
        <Support />
        <EventCards />
        <Doing />
        <TaaraAmbassador />
        <PromiseYouEverything
  heading="We can’t promise you everything"
  description="But we can promise you'll never be alone in your journey and you'll be part of something truly unique and innovative."
/>

        <HowToJoin
  heading="HOW TO JOIN"
  description="You can become a Taara Ambassador by applying directly or through a recommendation from an existing Ambassador."
  buttonText="Apply Now"
  onButtonClick={() => alert("Application Process Started")}
/>

        <OurAmbassadors />
        <LandingHeroImage
          alt="Discover taara quest game"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Taara+Quest+Game+Waitlist+Signup+Image.webp"
          heading="DISCOVER TAARA QUEST GAME"
          ctaText="join the waitlist"
          onClick={handleCTAClick}
        />
      </div>
    </NonProtectedLayout>
  );
};

export default Ambassadors;
