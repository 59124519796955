import React, { useEffect } from "react";
import "./Ambassadors.css";
import NonProtectedLayout from "../../layout/NonProtectedLayout/NonProtectedLayout";
import LandingHeroImage from "../../components/ambassadors/LandingHeroImage/LandingHeroImage";
import Practice from "../../components/custom-quest/Practice/Practice";
import CreateRoleplay from "../../components/custom-quest/CreteRolePlay/CreateRoleplay";
import HowItWorks from "../../components/custom-quest/HowItWorks/HowItWorks";
import PrimaryButton from "../../components/button/PrimaryButton";
import RealWorldRolePlay from "../../components/custom-quest/RealWorldRolePlay/RealWorldRolePlay";
import YouAreNotAlone from "../../components/custom-quest/YouAreNotAlone/YouAreNotAlone";
import PrimaryHeading from "../../components/primitives/PrimaryHeading";
import LazyVideo from "../../components/LazyVideo/LazyVideo";
import LazyImage from "../../components/LazyImage/LazyImage";
import useScreenDetector from "../../hooks/useScreenDetector";
import HowToJoin from "../../components/ambassadors/HowToJoin/HowToJoin";
const CustomQuest = () => {
  // useEffect(() => {
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);
  const { isMobile } = useScreenDetector();

  const handleCTAClick = () =>
    window.open(
      "https://airtable.com/appXDqkHbjSrk9c7g/pagLxU9pUGpo7cuO7/form",
      "_blank"
    );
    const profiles = [
      {
        name: "Alexandria Ravencroft",
        src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/9.webp",
        designation: "Head of Marketing",
      },
      {
        name: "Savita Upponi",
        src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/8.webp",
        designation: "Community Manager",
      },
      {
        name: "Natasha Marston-Sy",
        src: "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/7.webp",
        designation: "Trainee Marketing & Content Management",
      },
   
    ];
    
 
  return (
    <NonProtectedLayout showFooter={true}>
      {/* <LandingHeroVideo src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/Hero_Header-Image.webp" onClick={() => null}  /> */}
      <div className="AmbassadorsContainer">
        <div className="relative">
          <LandingHeroImage
            alt="Custom Quest Hero Image"
            src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/Hero Image.webp"
            heading="STEP INTO REAL-WORLD CONVERSATIONS BEFORE THEY EVEN HAPPEN"
            ctaText="Create Your Custom Quest"
            onClick={handleCTAClick}
            headingStyleClasses="w-[70%]"
            ctaCustomClasses="w-[300px] mobile:w-[200px]"
          />
          <div className="w-[669px] mobile:w-[265px] h-[124px] mobile:h-[80px] flex justify-center items-center rounded-[12px] bg-white shadow-customQuestShadow absolute -bottom-14 left-1/2 -translate-x-1/2">
            <p className="text-lg text-gray-600 w-[375px] mobile:w-[190px] mobile:text-xs text-center">
              Practice real-world scenarios through personalized
              roleplays—tailored to your life.
            </p>
          </div>
        </div>
        <Practice />
        <CreateRoleplay />
        {/* You are not alone */}
        <YouAreNotAlone />
        <LazyVideo
          className="TaaraIntroVideoArea"
          src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Taara+Demo+Video+1.mp4"
          poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Taara+Introduction+Video+Thumbnail_Taara_Page_first+video.png"
        />
        <HowItWorks />
        <div className="flex flex-col gap-24">
          <div className="flex flex-col">
            <h2 className="text-center text-primaryBlue text-[45px]">
              Why Real World Roleplay Works?
            </h2>
            <RealWorldRolePlay />
          </div>
          <div className="w-full flex justify-center">
            <PrimaryButton className="bg-primaryBlue" text="Start Now" />
          </div>
        </div>
        <div className="px-32 flex justify-between">
          <div>
            <PrimaryHeading
              heading="Expert Guidance"
              className="font-nohemi text-primaryBlue leading-0"
            />
            <PrimaryHeading heading="MEETS AI" />
          </div>
          <p className="w-[430px] text-justify">
            Before and after each roleplay, our top coaches are there for you
            for 1:1 chats, helping you dive deeper into your conversations. You
            can even give them access to your roleplay data to talk about your
            performance, all while our AI provides real-time feedback on your
            communication patterns and style.
          </p>
        </div>
        <div className="EightSection">
  <div className="profile-grid">
    {profiles?.map((profile, idx) => (
      <div className="profile-card" key={idx}>
        <div className="profile-card-inner">
          <div className="profile-card-front">
            <img
              src={profile.src}
              alt={profile.src}
              width={"100%"}
              height={isMobile ? "308px" : "100%"}
              className="team-image"
              bg="none"
            />
            <p>{profile.name}</p>
          </div>
          <div className="profile-card-back">
            <p>{profile.designation}</p>
            <div >{profile.name}</div>
          </div>
        </div>
      </div>
    ))}
  </div>
  <PrimaryButton className="bg-primaryBlue text-white flex justify-center items-center mt-10"  text="Unlock 1:1 Coaching" />

<div className="howtojoin" >
 

<HowToJoin
  heading=""
  description="WANT TO KNOW MORE ABOUT REALWORLD ROLEPLAY?         "
  buttonText="Take a Look at FAQs"
  onButtonClick={() => alert("Application Process Started")}
  isCustomquest={true}
/>
</div>


</div>
      </div>
    </NonProtectedLayout>
  );
};

export default CustomQuest;
