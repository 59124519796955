import React from "react";
import Slider from "react-slick";
import clsxm from "../../../utility/clsxm";

const carouselData = [
  {
    description: "For professionals seeking to say good bye to self-doubt",
    bgImage:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/1.webp",
  },
  {
    description:
      "For students thinking building important skills to thrive in the workplace.",
    bgImage:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/2.webp",
  },
  {
    description:
      "For early career individuals wanting to grow in self-advocacy and gearing up to promotion.",
    bgImage:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/3.webp",
  },
  {
    description:
      "For anyone seeking personal growth and communicate effectively in everyday life.",
    bgImage:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/4_1.webp",
  },
  {
    description:
      "For anyone seeking personal growth and communicate effectively in everyday life.",
    bgImage:
    "https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/3.webp",
  },
];

export default function CustomCarousel() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => (
      <div
        style={{
          position: "absolute",
          bottom: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: "10px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {dots}
      </div>
    ),
    customPaging: (i) => (
      <div
        className="custom-dot"
        style={{
          width: "30px",
          height: "6px",
          background: "#ddd", // Default gray color
          cursor: "pointer",
        }}
      />
    ),
  };

  return (
    <div className="relative">
      <Slider
        {...settings}
        beforeChange={(current, next) => {
          // Update dot colors dynamically
          const dots = document.querySelectorAll(".custom-dot");
          dots.forEach((dot, index) => {
            dot.style.background = index === next ? "white" : "#ddd";
          });
        }}
      >
        {carouselData.map((data, idx) => (
          <div
            key={idx}
            className={clsxm(
              "h-[435px] w-full flex justify-center items-center font-bold text-2xl rounded-[12px] "
            )}
          >
            <div
              className="w-full h-full flex flex-col items-center justify-center gap-[75px] bg-black/50 rounded-[12px]"
              style={{
                backgroundImage: `url(${data.bgImage})`, // Dynamic background image
                backgroundSize: "cover", // Equivalent to bg-cover
                backgroundPosition: "center", // Equivalent to bg-center
              }}
            >
              <h4 className="text-white text-[25px] font-nohemi">
                Who is Taara Quest for?
              </h4>
              <p className="text-white text-[32px] font-nohemi w-[496px] text-center leading-10">
                {data.description}
              </p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
