import React from "react";
import "./HowToJoin.css";
import roundLogo from "../../../images/round-logo.svg";

const HowToJoin = ({ heading, description, buttonText, onButtonClick,isCustomquest }) => {
  return (
    <div className="HowToJoinContainer" style={{width:isCustomquest?'70%':''}}>
      <div className="TopIconContainer">
        <img src={roundLogo} alt="round-logo" />
      </div>
      <div className="HowToJoinContent">
        <div className="HowToJoinHeading">{heading}</div>
        <div className="HowToJoinDescription" >{description}</div>
        <button className="HowToJoinButton" onClick={onButtonClick}>
          {buttonText}
        </button>
      </div>
    </div>
  );
};

export default HowToJoin;
