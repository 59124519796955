import React from "react";
import "./PromiseYouEverything.css";

const PromiseYouEverything = ({ heading, description }) => {
  return (
    <div className="PromiseYouEverythingContainer">
      <div className="PromiseYouEverythingContent">
        <div className="PromiseYouEverythingHeading">{heading}</div>
        <div className="PromiseYouEverythingDescription">{description}</div>
      </div>
    </div>
  );
};

export default PromiseYouEverything;
