import PrimaryButton from "../../button/PrimaryButton";
import LazyImage from "../../LazyImage/LazyImage";
import "./CreateRoleplay.css";

import React from "react";

const CreateRoleplay = () => {
  return (
    <div className="CreateRoleplayContainer">
      <LazyImage
        src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/custom-quest-subpage/Second+Image.webp"
        // src="https://taara-quest.s3.eu-central-1.amazonaws.com/images/ambassadors/What+ambassadors+do.webp"
        alt="Create Roleplay"
        className="CreateRoleplayLeftImage"
        bg="none"
      />
      <div className="flex flex-col gap-24 mobile:gap-6 justify-center w-[80%] mobile:w-[100%] mobile:items-center">
        <div className="flex flex-col gap-12 mobile:gap-6 text-gray-600 w-[193px]">
          <p className="text-xl mobile:text-center mobile:text-xs">
            With Taara Quest's RealWorld Roleplay feature, you can practice
            these moments before they even happen, so you're ready for IRL
            success.{" "}
          </p>
          <p className="text-[25px] mobile:text-xs text-primaryBlue mobile:text-center">
            Gain the skills and confidence to step into every discussion
            prepared and empowered.
          </p>
        </div>
        <PrimaryButton
          text="Create Your RealWord Roleplay"
          className="h-[46px] text-base font-nohemi"
        />
      </div>
    </div>
  );
};

export default CreateRoleplay;
